<template>
  <div class="d-flex align-center mt-1">
    <router-link :to="{name: 'home'}">
      <img alt="CoLocus" src="../../assets/colocus_logo.png" height="35" />
    </router-link>
  </div>
</template>

<script setup>
</script>
