<template>
  <v-sheet class="pa-2 bg-clcAmpBlue mt-0 footer-pos text-center">
    <a href="https://kp4cd.org/" target="_blank">
      Powered by the HuGeAMP | ACCELERATING MEDICINES PARTNERSHIP and AMP are registered service marks
      of the U.S. Department of Health and Human Service
    </a>
  </v-sheet>
</template>

<script>
</script>

<style scoped>

.footer-pos {
  position: relative;
}

a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
}

a:hover {
  font-weight: bold;
}
</style>
