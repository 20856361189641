<template>
  <div class="d-flex align-center mt-1">
    <a href="https://kp4cd.org/" target="_blank">
      <img alt="AMP Home" src="../../assets/amp_logo.png" height="36" class="mt-1"/>
    </a>
  </div>
</template>

<script>
</script>
